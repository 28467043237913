
  <template>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"


    >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Marchant
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
      <validation-provider
        #default="validationContext"
        name="Merchant Name"
        rules="required"
      >
        <b-form-group
          label="Merchant Name"
          label-for="merchant-name"
        >
          <b-form-input
            v-model="name"
            id="merchant-name"
            autofocus
            :state="getValidationState(validationContext)"
            trim
            placeholder="Merchant Name"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-2"
          type="submit"
        >
          Add
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          Cancel
        </b-button>
      </div>
      </b-form>
      </validation-observer>
    </template>

    </b-sidebar>
  </template>


<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    }

  },
  data(){
    return {
      name:""
    }
  },
  directives: {
    Ripple
  },
  setup(props, { emit }) {

    let name = ref('');
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(name)

    return {
      name,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    ...mapActions({
      addNewCategories:"merchants/addNewMerchants"
    }),
    onSubmit(){
      console.log("New Categories Name =="+JSON.stringify(this.name))
      this.addNewCategories(this.name)
      this.name = "";
    }
  }
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
