<template>
  <div style="margin-left:30px;margin-right:30px;">
    <h2><center>Merchants</center></h2>
    <br/><br/>
    <MerchantListAddNew
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    />
    <b-card
      no-body
      header="Merchant List" 
      class="mb-0 text-center"
    >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
        <label>Show</label>
        <v-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
      <div class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button
          variant="primary"
          @click="isAddNewUserSidebarActive = true"
        >
          <span class="text-nowrap">Add Merchant</span>
        </b-button>
      </div>
      </b-col>
      </b-row>
    </div>
    <div v-if="modalShow">
      <b-modal
        ref="refEditMerchant"
        title="Edit Merchant!!"
        v-model="modalShow"
        @hidden="resetModal"
        @ok="updateMerchant"
        ok-title="Save"
        ok-only

      >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          id="input-group-merchant-name"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="editMerchantState"
        >
          <b-form-input
            id="name-input"
            v-model="editMerchantName"
            :state="editMerchantState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
        </b-modal>
    </div>

    <b-table
      ref="refMerchantListTable"
      class="position-relative"
      :items="getSelectedMerchants"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >
    <template #cell(name)="data">
      <div class="text-nowrap">
        {{ data.item.name }}
      </div>
    </template>
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >

        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item @click="editMerchant(data.item)">
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

        <b-dropdown-item @click="deleteMerchant(data.item.id)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">Showing {{from }} to {{ to }} of {{ totalMerchants }} entries</span>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalMerchants"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
    </b-row>
  </div>
  </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import MerchantListAddNew from './MerchantAddNew.vue'
import { mapMutations, mapActions, mapState } from "vuex";
import BCardCode from '@core/components/b-card-code'
export default {
  name:"merchantList",
  data(){
    return {
      perPage:10,
      currentPage : 1,
      perPageOptions : [10, 25, 50, 100],
      searchQuery:'',
      tableColumns :[
        { key: 'name'},
        { key: 'actions' }
      ],
      skip:0,
      from:0,
      to:0,
      isAddNewUserSidebarActive:false,
      refMerchantListTable:"merchants",
      modalShow : false,
      selectedMerchant: null,
      editMerchantName:'',
      editMerchantState:null


    }
  },
  mounted(){
    var query = {
      skip:0,
      limit:10
    }
    this.loadMerchants(query)
  },
  computed:{
    ...mapState({
      merchants :(state) =>{
       return state.merchants.merchants;
     },
     totalMerchants: (state) =>{
       console.log("totalMerchants =="+JSON.stringify(state.merchants.totalMerchants))
       return state.merchants.totalMerchants;
     },
     getSelectedMerchants: state => {
      return state.merchants.merchants;
     },
    })
  },
  watch:{
    currentPage:{
      handler(){
        this.skip = (this.currentPage - 1) * this.perPage
        this.reload()
      }
    },
    searchQuery:{
      handler(){
        console.log("searchQuery value = "+this.searchQuery);
        this.searchFormQuery()
      }
    },
    perPage:{
      handler(){
        this.skip = (this.currentPage - 1) * this.perPage
        this.reload()
      }
    },
    merchants:{
      handler(){
        const merchantsLength = this.merchants.length;
        this.from = this.perPage * (this.currentPage - 1) + (merchantsLength ? 1 : 0);
        this.to =  this.perPage * (this.currentPage - 1) + merchantsLength;
      }
    },
    searchQuery: {
      handler() {
        this.searchMerchants();
      }
    }
  },
  methods:{
    ...mapActions({
      loadMerchants:"merchants/fetchAllMerchants",
      deleteMerchant:"merchants/deleteMerchant",
      updateMerchantName:"merchants/editMerchant",
      fetchMerchant: "merchants/fetchMerchants"

    }),
    reload(){
      this.loadMerchants({skip:this.skip,limit:this.perPage})
    },
    searchMerchants(){
      this.fetchMerchant({$search: this.searchQuery, $limit: 1000})
    }, 
    editMerchant(merchant){
      this.modalShow = true
      this.selectedMerchant = merchant;
      this.editMerchantName = merchant.name
    },
    searchFormQuery(){
      this.loadSearchRes({$search:this.searchQuery})
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.editMerchantState = valid
        return valid
      },
      resetModal() {
        this.selectedMerchant = null
        this.editMerchantState = null
      },
      updateMerchant(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.updateMerchantName({id:this.selectedMerchant.id,name:this.editMerchantName})
        this.$nextTick(() => {
          this.modalShow = false
        })
      }
  },
  components: {
    MerchantListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,
    vSelect,
    BCardCode
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
